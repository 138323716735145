import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import oreo from './media/oreo.jpg';
import oreo1 from './media/oreo1.jpg';
import chocolate from './media/chocolate.jpg';
import chocolate1 from './media/chocolate1.jpg';
import cupcake1 from './media/cupcake1.jpg';
import cupcake2 from './media/cupcake2.jpg';
import cupcake3 from './media/cupcake3.jpg';
import pineapplecake from './media/pineapplecake.jpg';
import Carousel from 'react-bootstrap/Carousel';

class Cakeshop extends React.Component{
    // Constructor
    constructor(props){
        super(props);

        // Set default states and values
        this.state = {
            chocolate: '', chocolatestate: false, chocolatebutton: 'Show Details',
            cupcake: '', cupcakestate: false, cupcakebutton: 'Show Details',
            oreo: '', oreostate: false, oreobutton: 'Show Details',
            pineapplecake: '', pineapplecakestate: false, pineapplecakebutton: 'Show Details'
        };
    }

    // Function for Product Details Click
    toggleHideShow(item){
        if (item === 'chocolate'){
            if (this.state.chocolatestate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> 6 inch cake available in either 2 or 3 layers</div>
                    <hr/>
                    <div><b>Prices:</b> 2 layers - $60, 3 layers - $75</div>
                    <hr/>
                    <div><b>Naked or Full frosting:</b><b><br/>Buttercream: </b>chocolate, vanilla, mint, coconut, caramel,
                    marshmallow<br/><b>Ganache: </b>semi-sweet chocolate</div>
                </div>
                this.setState({chocolate: details});
                this.setState({chocolatestate: true});
                this.setState({chocolatebutton: 'Hide Details'});
            }
    
            if(this.state.chocolatestate === true){
                this.setState({chocolate: ''});
                this.setState({chocolatestate: false});
                this.setState({chocolatebutton: 'Show Details'});
            }
        }

        if (item === 'cupcake'){
            if (this.state.cupcakestate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> Cupcakes, cute enough for any occassion
                    </div>
                    <hr></hr>
                    <div><b>Quantities:</b> Minimum order of 9</div>
                    <hr></hr>
                    <div><b>Price: </b>$4 - $5 Each</div>
                    <hr></hr>
                    <div><b>Cake Flavours: </b>vanilla, chocolate, mint, coconut, oreo<br/><i>*Other flavours
                            available upon request</i></div>
                    <hr/>
                    <div><b>Frosting Flavours:</b><br/><b>Buttercream: </b>chocolate, vanilla, mint, coconut, caramel,
                    marshmallow<br/><b>Ganache: </b>semi-sweet chocolate</div>
                </div>
                this.setState({cupcake: details});
                this.setState({cupcakestate: true});
                this.setState({cupcakebutton: 'Hide Details'});
            }
    
            if(this.state.cupcakestate === true){
                this.setState({cupcake: ''});
                this.setState({cupcakestate: false});
                this.setState({cupcakebutton: 'Show Details'});
            }
        }

        if (item === 'oreo'){
            if (this.state.oreostate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> 6 inch cake available in either 2 or 3 layers</div>
                    <hr/>
                    <div><b>Prices:</b> 2 layers - $60, 3 layers - $75</div>
                    <hr/>
                    <div><b>Naked or Full frosting:</b><b><br/>Buttercream: </b>chocolate, vanilla, mint, coconut, caramel,
                    marshmallow<br/><b>Ganache: </b>semi-sweet chocolate</div>
                </div>
                this.setState({oreo: details});
                this.setState({oreostate: true});
                this.setState({oreobutton: 'Hide Details'});
            }
    
            if(this.state.oreostate === true){
                this.setState({oreo: ''});
                this.setState({oreostate: false});
                this.setState({oreobutton: 'Show Details'});
            }
        }

        if (item === 'pineapplecake'){
            if (this.state.pineapplecakestate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> One layer cake available in 6 inch, 8 inch or 10 inch sizes</div>
                    <hr/>
                    <div><b>Prices:</b> 6 inch - $55, 8 inch - $65, 10 inch - $75</div>
                    <hr/>
                    <div><b>Toppings:</b> Maraschino cherries (optional) </div>
                    <hr/>
                </div>
                this.setState({pineapplecake: details});
                this.setState({pineapplecakestate: true});
                this.setState({pineapplecakebutton: 'Hide Details'});
            }
    
            if(this.state.pineapplecakestate === true){
                this.setState({pineapplecake: ''});
                this.setState({pineapplecakestate: false});
                this.setState({pineapplecakebutton: 'Show Details'});
            }
        }
    }

    render(){
        return (
            // Main Container
            <>
            {/* Top Banner */}
            <Row id="socialbarrow" Style="color: white; text-align: center; font-size: 0.9rem; padding: 0.7rem;">Prices are quoted in BBD. Orders should be placed 10 days in advance. A 50% deposit is required on orders of $60 and over; to be made at least 
            7 days in advance. Orders made 3 or less days in advance will incur a rush fee.</Row>
            <Container id="centercontainer">
                
                {/* Cursive Text Cakes Header */}

                <div className="minmagicheader">
                <Row>
                    <Col>
                        <span id="cursivetext1">No matter the occassion there is
                        <span id="standouttext"> ALWAYS </span> need for cake!</span><br/>
                        <span id="normalfont">Have a look at our selection offered for birthdays, parties or just 
                        because -</span>
                    </Col>
                </Row>
                <Row><Col></Col><Col id="signature">Akilah & Saam <svg id="heart" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.516 3c2.382 0 4.487 1.564 4.487 4.712 0 4.963-6.528 8.297-10.003 11.935-3.475-3.638-10.002-6.971-10.002-11.934 0-3.055 2.008-4.713 4.487-4.713 3.18 0 4.846 3.644 5.515 5.312.667-1.666 2.333-5.312 5.516-5.312zm0-2c-2.174 0-4.346 1.062-5.516 3.419-1.17-2.357-3.342-3.419-5.515-3.419-3.403 0-6.484 2.39-6.484 6.689 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-4.586-3.414-6.689-6.484-6.689z"/></svg></Col></Row>
                </div>

                <hr></hr>

                {/* Miniature Magic Products */}
                <Row className="shopdropdown topmargin bottommargin">

                    {/* Chocolate Cake */}
                    <Col id="cakecol" lg>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={chocolate}
                                alt="First Chocolate Cake"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={chocolate1}
                                alt="Second Chocolate Cake"
                                />
                            </Carousel.Item>
                        </Carousel>
                            <div className="cursivetext">Chocolate</div>
                            <Button onClick={() => this.toggleHideShow('chocolate')} block>{this.state.chocolatebutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.chocolate}
                            </Row>
                    </Col>

                    {/* Oreo Cake */}
                    <Col id="cakecol" lg>
                    <Carousel>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={oreo}
                                alt="First Oreo Cake"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={oreo1}
                                alt="Second Oreo Cake"
                                />
                            </Carousel.Item>
                        </Carousel>
                            <div className="cursivetext">Vanilla</div>
                            <Button onClick={() => this.toggleHideShow('oreo')} block>{this.state.oreobutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.oreo}
                            </Row>
                    </Col>
                </Row>
                <Row className="shopdropdown topmargin bottommargin">
                    {/* Cupcake */}
                    <Col id="cakecol" lg>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={cupcake1}
                                alt="Second Cupcake"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={cupcake2}
                                alt="Third Cupcake"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={cupcake3}
                                alt="Fourth Cupcake"
                                />
                            </Carousel.Item>
                        </Carousel>
                        <div className="cursivetext">Cupcakes<div></div></div>
                        <Button onClick={() => this.toggleHideShow('cupcake')} block>{this.state.cupcakebutton}</Button>
                        <Row className="justify-content-center">
                            {this.state.cupcake}
                        </Row>
                    </Col>
                    <Col id="cakecol" lg>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={pineapplecake}
                                alt="Pineapple Upside Down Cake"
                                />
                            </Carousel.Item>
                        </Carousel>
                        <div className="cursivetext">Pineapple Upside Down Cake</div>
                        <Button onClick={() => this.toggleHideShow('pineapplecake')} block>{this.state.pineapplecakebutton}</Button>
                        <Row className="justify-content-center">
                            {this.state.pineapplecake}
                        </Row>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }
}
export default Cakeshop;