import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
 
class Thankyou extends React.Component {
    constructor(){
        super();
        this.state = {};
    }

    render(){
        return (
            // Main Container
            <Container className="justify-content-center topmargin1" id="thankyou" fluid>
                <h1>Thank you for submitting your enquiry!</h1>
                <Button href="/">Click to return to home!</Button>
            </Container>
            );
    }
    
}
export default Thankyou;