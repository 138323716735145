import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import browniecookie from './media/browniecookie.jpg';

class Cookieshop extends React.Component{
    // Constructor
    constructor(props){
        super(props);

        // Set default states and values
        this.state = {
            browniecookies: '', browniecookiesstate: false, browniecookiesbutton: 'Show Details',
            cookiebars: '', cookiebarsstate: false, cookiebarsbutton: 'Show Details',
        };
    }

    // Function for Product Details Click
    toggleHideShow(item) {
        if (item === 'browniecookies') {
            if (this.state.browniecookiesstate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> Decadent, fudgey chocolate cookie-brownie hybrid sprinkled with sea salt and topped with chocolate chips</div>
                    <hr/>
                    <div><b>Quantities:</b> Minimum order 6</div>
                    <hr/>
                    <div><b>Price:</b> $4.00 Each</div>
                </div>
                this.setState({browniecookies: details});
                this.setState({browniecookiesstate: true});
                this.setState({browniecookiesbutton: 'Hide Details'});
            }
    
            if(this.state.browniecookiesstate === true){
                this.setState({browniecookies: ''});
                this.setState({browniecookiesstate: false});
                this.setState({browniecookiesbutton: 'Show Details'});
            }
        }

        if (item === 'cookiebars') {
            if (this.state.cookiebarsstate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> Semi sweet chocolate chip brown butter cookie squares with a dulce de leche center, loaded with chocolate chips</div>
                    <hr/>
                    <div><b>Quantities:</b> Minimum order 12</div>
                    <hr/>
                    <div><b>Price:</b> $4.00 Each</div>
                </div>
                this.setState({cookiebars: details});
                this.setState({cookiebarsstate: true});
                this.setState({cookiebarsbutton: 'Hide Details'});
            }
    
            if(this.state.cookiebarsstate === true){
                this.setState({cookiebars: ''});
                this.setState({cookiebarsstate: false});
                this.setState({cookiebarsbutton: 'Show Details'});
            }
        }
    }

    render(){
        return (
            // Main Container
            <>
            {/* Top Banner */}
            <Row id="socialbarrow" Style="color: white; text-align: center; font-size: 0.9rem; padding: 0.7rem;">Prices are quoted in BBD. Orders should be placed 10 days in advance. A 50% deposit is required on orders of $60 and over; to be made at least 
            7 days in advance. Orders made 3 or less days in advance will incur a rush fee.</Row>
            <Container id="centercontainer">
                
                {/* Cursive Text Cakes Header */}

                <div className="minmagicheader">
                <Row>
                    <Col>
                        <span id="cursivetext1">Check out our
                        <span id="standouttext"> Cookies! </span></span><br/>
                    </Col>
                </Row>
                <Row><Col></Col><Col id="signature">Akilah & Saam <svg id="heart" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.516 3c2.382 0 4.487 1.564 4.487 4.712 0 4.963-6.528 8.297-10.003 11.935-3.475-3.638-10.002-6.971-10.002-11.934 0-3.055 2.008-4.713 4.487-4.713 3.18 0 4.846 3.644 5.515 5.312.667-1.666 2.333-5.312 5.516-5.312zm0-2c-2.174 0-4.346 1.062-5.516 3.419-1.17-2.357-3.342-3.419-5.515-3.419-3.403 0-6.484 2.39-6.484 6.689 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-4.586-3.414-6.689-6.484-6.689z"/></svg></Col></Row>
                </div>

                <hr></hr>

                {/* Cookie Products */}
                <div className="shopdropdown bottommargin">
                    <Row>
                        {/* Brownie Cookies*/}
                        <Col className="bottommargin" xl>
                            <Image fluid src={browniecookie} rounded alt="browniecookiesimage"/>
                            <div className="cursivetext minmagiccaptions">Brownie Cookies</div>
                            <Button className="browniecookiesbutton" onClick={() => this.toggleHideShow('browniecookies')}>{this.state.browniecookiesbutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.browniecookies}
                            </Row>
                        </Col> 

                        {/* Cookie Bars*/}
                        <Col className="bottommargin" xl>
                            { /*<Image fluid src rounded alt="cookiebarsimage"/> */}
                            <div className="cursivetext minmagiccaptions">Chocolate Chip Cookie Bars</div>
                            <Button className="cookiebarsbutton" onClick={() => this.toggleHideShow('cookiebars')}>{this.state.cookiebarsbutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.cookiebars}
                            </Row>
                        </Col> 
                    </Row>
                </div>
            </Container>
            </>
        );
    }
}
export default Cookieshop;