import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
//import Image from 'react-bootstrap/Image';
import heart1 from './media/heart1.JPG';
import heart2 from './media/heart2.jpg';
import heart3 from './media/heart3.jpg';
import heart4 from './media/heart4.jpg';

class Rollshop extends React.Component{
    // Constructor
    constructor(props){
        super(props);

        // Set default states and values
        this.state = {
            heart: '', heartstate: false, heartbutton: 'Show Details',
        };
    }

    // Function for Product Details Click
    toggleHideShow(item) {
        if (item === 'heart'){
            if (this.state.heartstate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> Each gift box contains 2 decadent cake hearts and is decorated with ribbon and a gift tag specially for your Valentine.</div>
                    <hr/>
                    <div><b>Price:</b> $30.00</div>
                    <hr/>
                    <div><i>Orders close on February 11, 2022</i></div>
                    <div><i>Collection dates: February 12 and 13, 2022</i></div>
                </div>
                this.setState({heart: details});
                this.setState({heartstate: true});
                this.setState({heartbutton: 'Hide Details'});
            }
    
            if(this.state.heartstate === true){
                this.setState({heart: ''});
                this.setState({heartstate: false});
                this.setState({heartbutton: 'Show Details'});
            }
        }

    }

    render(){
        return (
            // Main Container
            <>
            {/* Top Banner */}
            <Row id="socialbarrow" Style="color: white; text-align: center; font-size: 0.9rem; padding: 0.7rem;">Prices are quoted in BBD. Orders should be placed 10 days in advance. A 50% deposit is required on orders of $60 and over; to be made at least 
            7 days in advance. Orders made 3 or less days in advance will incur a rush fee.</Row>
            <Container id="centercontainer">
                
                {/* Cursive Text Cakes Header */}

                <div className="minmagicheader">
                <Row>
                    <Col>
                        <span id="cursivetext1"><span id="standouttext">Valentine's Day</span></span><br/>
                        <span id="normalfont">Delectable treats made for the season of love - to share with that special someone or to treat yourself. Created by us, just for you - </span>
                    </Col>
                </Row>
                <Row><Col></Col><Col id="signature">Akilah & Saam <svg id="heart" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.516 3c2.382 0 4.487 1.564 4.487 4.712 0 4.963-6.528 8.297-10.003 11.935-3.475-3.638-10.002-6.971-10.002-11.934 0-3.055 2.008-4.713 4.487-4.713 3.18 0 4.846 3.644 5.515 5.312.667-1.666 2.333-5.312 5.516-5.312zm0-2c-2.174 0-4.346 1.062-5.516 3.419-1.17-2.357-3.342-3.419-5.515-3.419-3.403 0-6.484 2.39-6.484 6.689 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-4.586-3.414-6.689-6.484-6.689z"/></svg></Col></Row>
                </div>

                <hr></hr>
                <Row className="shopdropdown topmargin bottommargin">
                    {/* Cupcake */}
                    <Col id="cakecol" lg>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={heart1}
                                alt="First Heart"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={heart2}
                                alt="Second Heart"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={heart3}
                                alt="Third Heart"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                className="d-block w-100"
                                src={heart4}
                                alt="Fourth Heart"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
                {/* Cinnamon Roll Products */}
                <div className="shopdropdown bottommargin">
                    <Row>
                            {/* Cinnamon Rolls*/}
                            <Col className="bottommargin" xl>
                                {/*<Image fluid rounded alt="heartimage"/>*/}
                                <div className="cursivetext minmagiccaptions">You Are My Heart Gift Box</div>
                                
                                <Button className="heartbutton" onClick={() => this.toggleHideShow('heart')}>{this.state.heartbutton}</Button>
                                <Row className="justify-content-center">
                                    {this.state.heart}
                                </Row>
                                
                            </Col> 
                    </Row>
                </div>
            </Container>
            </>
        );
    }
}
export default Rollshop;