import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import saamiya from './media/saamiya.jpg';
import akilah from './media/akilah.jpg';
import cupcake from './media/aboutimage.jpg';
 
const About = () => {
    return (
    // Main Container
    <Container id="about" fluid>
        {/* Title */}
        <Row id="abouttitle">
            <p>Meet the Team!</p>
        </Row>

        {/* About Text */}
        <Row id="abouttext">
            Two best friends with shared passions: baking, service and literally anything cute, Oh Sugar! is a manifestation of wanting to spread joy through treats. Know how Bajans say “Oh Shoot!”? It’s something kind of like that!<span role="img" aria-label="emoji">😊</span>
        </Row>

        {/* Main About */}
        <Row id="abouttext">
            <Col md>
                <Image fluid src={akilah} rounded alt="akiimage"/>
                <h1 id="aboutnames">Akilah</h1>
            </Col>
            <Col md>
                <Image fluid src={saamiya} rounded alt="samimage" height="300" width="300"/>
                <h1 id="aboutnames">Saamiya</h1>
            </Col>              
        </Row>

        {/* Charity */}
        <Row id="abouttext">
            Living by the mantra: the world would be a better place if everyone committed random acts of cupcakes, Oh Sugar! is meant to be a youth business, supporting other youth businesses with a core value of service.
            <br/><br/>
            <Image fluid src={cupcake} rounded alt="cupcakes1"/>
            <br/><br/>
            Every month, a non-governmental organization, charity or good cause will be chosen to be the recipient of donations, either or a combination of:
            <br/>   
            • Monetary – a percentage of all profits for that month<br/>
            • In kind – treats sponsored by Oh Sugar!<br/>
            • Time – Oh Sugar! volunteering<br/>
        </Row>
        <Row id="aboutcharity">
            <a href="/charity">Click to see this month's charity of choice!</a>
        </Row>
    </Container>
    );
}
export default About;