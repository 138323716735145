import React from 'react';
import Container from 'react-bootstrap/Container';
//import Image from 'react-bootstrap/Image';
//import img from './media/growsmart.png';

const Ourwork = () => {
    return(
        <Container fluid id="ourwork">
            {/* About Charity */}
            <script src="https://snapwidget.com/js/snapwidget.js"></script>
            <iframe src="https://snapwidget.com/embed/961026" title="igfeed" class="snapwidget-widget" height="400px" allowtransparency="true" frameborder="0" scrolling="yes" Style="border:none; overflow:hidden;  width:100%; "></iframe>
        </Container> 
    )
}
export default Ourwork;