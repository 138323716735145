import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
//import Select from 'react-bootstrap/Select'
import firebase from 'firebase/app';
import 'firebase/database';
import Modal from 'react-bootstrap/Modal';
import { Redirect } from "react-router-dom";
import emailjs from 'emailjs-com';

class Enquiry extends React.Component {
   // Constructor
   constructor() {
      super();

      //this.nodemailer = require("nodemailer");

      // Set Default States and Values
      this.state = { valentinesproducts: ["You Are My Heart Gift Box"],
                     minmagicproducts: ["Millionaire's Shortbread Bites","Mini Cupcakes","Personal Cake","Mini Cheesecakes","Pineapple Upside Down Bites", "Chocolate Chip Cookie Bites"],
                     cakeproducts: ["Chocolate Cake", "Vanilla Cake", "Cupcakes", "Pineapple Upside Down Cake"],
                     cakeaddons : ["Colour Theme", "Fresh Fruit", "Cake Topper", "Gold Leaf", "Ribbon and Gift Tag"],
                     cupcakeaddons : ["Colour Theme", "Fresh Fruit", "Cupcake Topper", "Ribbon and Gift Tag"],
                     minicupcakeaddons : ["Colour Theme", "Ribbon and Gift Tag"],
                     rollproducts: ["Cinnamon Rolls"],
                     cookieproducts: ["Brownie Cookies", "Chocolate Chip Cookie Bars"],
                     cakeflavours: ['Vanilla','Chocolate','Mint', 'Coconut', 'Oreo'],
                     frostingflavours: ["Chocolate", "Vanilla", "Mint", "Coconut", "Caramel", "Marshmallow", "Semi-Sweet Chocolate (Ganache)"],
                     drizzleflavours: ["Original (Plain)", "Coffee Drizzle", "Chocolate Drizzle", "Vanilla Bean Drizzle", "Caramel Drizzle", "Mint Drizzle"],
                     chipflavours: ["Butterscotch", "Semi-Sweet Chocolate", "Milk Chocolate", "White Chocolate", "Espresso"],
                     minicupcakequantity: [20, 36, 48],
                     firstname: "", lastname: "", email: "", phone: "", notes: "", date: "", product: "",
                     product1state: false, product1quantity: "10", product1topping: "",
                     product2state: false, product2flavour: "", product2frosting: "", product2quantity: "20", product2quantity1: "1",
                     product2addon1: false, product2addon2: false,
                     product3state: false, product3flavour: "", product3frosting: "", product3layers: "",
                     product4state: false, product4flavour: "", product4quantity: "12", product4base: "", product4topping: "",
                     product5state: false, product5quantity: "9",
                     product6state: false, product6quantity: "10", product6topping: "",
                     product7state: false, product7frosting: "", product7size: "", product7layers: "", product7frostingtype: "", product7addons: "",
                     product7addon1: false, product7addon2: false, product7addon3: false, product7addon4: false, product7addon5: false,
                     product8state: false, product8frosting: "", product8size: "", product8layers: "", product8frostingtype: "", product8addons: "",
                     product8addon1: false, product8addon2: false, product8addon3: false, product8addon4: false, product8addon5: false,
                     product9state: false, product9flavour: "", product9frosting: "", product9quantity: "9", product9quantity1: "1", product9addons: "",
                     product9addon1: false, product9addon2: false, product9addon3: false, product9addon4: false,
                     product10state: false, product10size: "", product10topping: "",
                     product11state: false, product11drizzle: "", product11chips: "", product11quantity: "6",
                     product12state: false, product12quantity: "6",
                     product13state: false, product13quantity: "12",
                     product14state: false, product14quantity: "2",
                     paymentmethod: "Cash", additional: "", queries: "", modalstate: false, redirect: null,
                     customizemillionaires: null,
                     customizeminicupcakes: null,
                     customizepersonalcake: null,
                     customizeminicheesecakes: null,
                     customizepineapples: null,
                     customizecookiebites: null,
                     customizechocolatecake: null,
                     customizevanillacake: null,
                     customizecupcakes: null,
                     customizeupsidedowncake: null,
                     customizecinnamonrolls: null,
                     customizebrowniecookies: null,
                     customizecookiebars: null,
                     customizevalentines: null,
                     pickuplocation: "", boxnote: "", collectiondate: ""
                  };
   }

   initDatabase(){
      // Database Stuff START
      // Production Database Config
      // eslint-disable-next-line
      
      let init;

       let config = {
         apiKey: "AIzaSyC2qHjxDWCFPaQN-0Mnhh-l_xGByIxAihQ",
         authDomain: "ohsugar-effc2.firebaseapp.com",
         databaseURL: "https://ohsugar-effc2.firebaseio.com/",
         storageBucket: "bucket.appspot.com"
      };

      // Initialize Firebase Application with config variable

      if (firebase.apps.length === 0) {
         init = firebase.initializeApp(config);
     }
     
     return init;
      // Database Stuff END
   }

   writeEnquiryData(){
      let db = this.initDatabase();
      let ref = db.database().ref('enquiries').push();
      
      // Write Data to Database
      ref.set({
         firstname: this.state.firstname,
         lastname: this.state.lastname,
         email: this.state.email,
         phone: this.state.phone,
         date: this.state.date,
         product: this.state.product,
         additional: this.state.additional,
         queries: this.state.queries,
         timestamp: firebase.database.ServerValue.TIMESTAMP
      });

      var templateParams = {
         firstName: `First Name: ${this.state.firstname}`,
         lastName: `Last Name: ${this.state.lastname}`,
         email: `Email: ${this.state.email}`,
         phone: `Phone Number: ${this.state.phone}`,
         date: `Date: ${this.state.date}`,
         product: `Product(s): ${this.state.product}`,
         paymentmethod: `Payment Method: ${this.state.paymentmethod}`,
         additional: `Additional Details: ${this.state.additional}`,
         queries: `Queries: ${this.state.queries}`,
     };

      emailjs.init("user_hTGrQjfPnxyrDZn6JQrek");
      emailjs.send('service_v8zhzpa', 'template_gmal7tg', templateParams, 'user_hTGrQjfPnxyrDZn6JQrek')
      .then(function(response) {
         console.log('SUCCESS!', response.status, response.text);
      }, function(error) {
         console.log('FAILED...', error);
      });    
   }

   // Function to handle when enquiry form is submitted
   /*{this.state.product5state === false ? "" : `${this.state.product5state} (Quantity: ${this.state.product5quantity})`}
               {this.state.product6state === false ? "" : `${this.state.product6state} (Quantity: ${this.state.product6quantity}, ${this.state.product6topping})`}
               {this.state.product7state === false ? "" : `${this.state.product7state} (Frosting: ${this.state.product7frosting}, Frosting Type: ${this.state.product7frostingtype}, Size: ${this.state.product7size}, Layers: ${this.state.product7layers})`}
               {this.state.product8state === false ? "" : `${this.state.product8state} (Frosting: ${this.state.product8frosting}, Frosting Type: ${this.state.product8frostingtype}, Size: ${this.state.product8size}, Layers: ${this.state.product8layers})`}
               {this.state.product9state === false ? "" : `${this.state.product9state} (Quantity: ${this.state.product9quantity}, Flavour: ${this.state.product9flavour}, Frosting: ${this.state.product9frosting})`}
               {this.state.product10state === false ? "" : `${this.state.product10state} (Size: ${this.state.product10size}, Topping: ${this.state.product10topping})`}
               {this.state.product11state === false ? "" : `${this.state.product11state} (Quantity: ${this.state.product11quantity}, Drizzle Flavour: ${this.state.product11drizzle}, Chip Flavour: ${this.state.product11chips})`}
               {this.state.product11state === false ? "" : `${this.state.product12state} (Quantity: ${this.state.product12quantity})`}
               {this.state.product11state === false ? "" : `${this.state.product13state} (Quantity: ${this.state.product13quantity})`} */
   handleSubmit(){
      let productarray = "";

      if(this.state.product1state !== false)
         productarray+=(`${this.state.product1state} (Quantity: ${this.state.product1quantity}, ${this.state.product1topping})\n`);

      if(this.state.product2state !== false)
         productarray+=(`${this.state.product2state} (Box Size: ${this.state.product2quantity}, Number of Boxes: ${this.state.product2quantity1}, Flavour: ${this.state.product2flavour}, Frosting: ${this.state.product2frosting}, Add-Ons: ${this.state.product2addon1 === false ? "" : this.state.product2addon1}, ${this.state.product2addon2 === false ? "" : this.state.product2addon2})\n`);

      if(this.state.product3state !== false)
         productarray+=(`${this.state.product3state} (Flavour: ${this.state.product3flavour}, Frosting: ${this.state.product3frosting}, Layers: ${this.state.product3layers})\n`);

      if(this.state.product4state !== false)
         productarray+=(`${this.state.product4state} (Quantity: ${this.state.product4quantity}, Flavour: ${this.state.product4flavour}, Cookie Base: ${this.state.product4base}, Topping: ${this.state.product4topping})\n`);

      if(this.state.product5state !== false)
         productarray+=(`${this.state.product5state} (Quantity: ${this.state.product5quantity})\n`);

      if(this.state.product6state !== false)
         productarray+=(`${this.state.product6state} (Quantity: ${this.state.product6quantity}, ${this.state.product6topping})\n`);
      
      if(this.state.product7state !== false)
         productarray+=(`${this.state.product7state} (Frosting: ${this.state.product7frosting}, Frosting Type: ${this.state.product7frostingtype}, Size: ${this.state.product7size}, Layers: ${this.state.product7layers}, Add-Ons: ${this.state.product7addon1 === false ? "" : this.state.product7addon1}, ${this.state.product7addon2 === false ? "" : this.state.product7addon2}, ${this.state.product7addon3 === false ? "" : this.state.product7addon3}, ${this.state.product7addon4 === false ? "" : this.state.product7addon4}, ${this.state.product7addon5 === false ? "" : this.state.product7addon5})\n`);

      if(this.state.product8state !== false)
         productarray+=(`${this.state.product8state} (Frosting: ${this.state.product8frosting}, Frosting Type: ${this.state.product8frostingtype}, Size: ${this.state.product8size}, Layers: ${this.state.product8layers}, Add-Ons: ${this.state.product8addon1 === false ? "" : this.state.product8addon1}, ${this.state.product8addon2 === false ? "" : this.state.product8addon2}, ${this.state.product8addon3 === false ? "" : this.state.product8addon3}, ${this.state.product8addon4 === false ? "" : this.state.product8addon4}, ${this.state.product8addon5 === false ? "" : this.state.product8addon5})\n`);

      if(this.state.product9state !== false)
         productarray+=(`${this.state.product9state} (Box Size: ${this.state.product9quantity}, Number of Boxes: ${this.state.product9quantity1},  Flavour: ${this.state.product9flavour}, Frosting: ${this.state.product9frosting}, Add-Ons: ${this.state.product9addon1 === false ? "" : this.state.product9addon1}, ${this.state.product9addon2 === false ? "" : this.state.product9addon2}, ${this.state.product9addon3 === false ? "" : this.state.product9addon3}, ${this.state.product9addon4 === false ? "" : this.state.product9addon4})\n`);

      if(this.state.product10state !== false)
         productarray+=(`${this.state.product10state} (Size: ${this.state.product10size}, Topping: ${this.state.product10topping})\n`);
      
      if(this.state.product11state !== false)
         productarray+=(`${this.state.product11state} (Quantity: ${this.state.product11quantity}, Drizzle Flavour: ${this.state.product11drizzle}, Chip Flavour: ${this.state.product11chips})\n`);

      if(this.state.product12state !== false)
         productarray+=(`${this.state.product12state} (Quantity: ${this.state.product12quantity})\n`);

      if(this.state.product13state !== false)
         productarray+=(`${this.state.product13state} (Quantity: ${this.state.product13quantity})\n`);

      if(this.state.product14state !== false)
         productarray+=(`${this.state.product14state} (Quantity: ${this.state.product14quantity}, Collection Date: ${this.state.collectiondate}, Pickup Location: ${this.state.pickuplocation}, Gift Tag: ${this.state.boxnote})\n`);

      this.setState({product: productarray});
      this.setState({modalstate: true});
   }

   confirmSubmit(){
      this.setState({modalstate: false});
      this.writeEnquiryData();
      this.setState({ redirect: "/thankyou" });
   }

   // Function to handle when values in form are changed
   handleFormChange(event){
      if (event.target.name === "firstname"){
         this.setState({firstname: event.target.value});
      }
      if (event.target.name === "lastname"){
         this.setState({lastname: event.target.value});
      }
      if (event.target.name === "email"){
         this.setState({email: event.target.value});
      }
      if (event.target.name === "phone"){
         this.setState({phone: event.target.value});
      }
      if (event.target.name === "notes"){
         this.setState({notes: event.target.value});
      }
      if (event.target.name === "date"){
         this.setState({date: event.target.value});
      }
      if (event.target.name === "paymentmethod"){
         this.setState({paymentmethod: event.target.value});
      }
      if (event.target.name === "additional"){
         this.setState({additional: event.target.value});
      }
      if (event.target.name === "queries"){
         this.setState({queries: event.target.value});
      }
      if (event.target.name === "product1quantity"){
         this.setState({product1quantity: event.target.value});
      }
      if (event.target.name === "product1topping"){
         this.setState({product1topping: event.target.value});
      }
      if (event.target.name === "product2quantity"){
         this.setState({product2quantity: event.target.value});
      }
      if (event.target.name === "product2quantity1"){
         this.setState({product2quantity1: event.target.value});
      }
      if (event.target.name === "product2flavour"){
         this.setState({product2flavour: event.target.value});
      }
      if (event.target.name === "product2frosting"){
         this.setState({product2frosting: event.target.value});
      }
      if (event.target.name === "product2addons"){
         // Use states to manipulate addons similiar to product addons
         if (event.target.value === "Colour Theme" && event.target.checked === true)
            this.setState({product2addon1: event.target.value});
         if (event.target.value === "Colour Theme" && event.target.checked === false)
            this.setState({product2addon1: false});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === true)
            this.setState({product2addon2: event.target.value});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === false)
            this.setState({product2addon2: false});
      }
      if (event.target.name === "product3flavour"){
         this.setState({product3flavour: event.target.value});
      }
      if (event.target.name === "product3frosting"){
         this.setState({product3frosting: event.target.value});
      }
      if (event.target.name === "product3layers"){
         this.setState({product3layers: event.target.value});
      }
      if (event.target.name === "product4flavour"){
         this.setState({product4flavour: event.target.value});
      }
      if (event.target.name === "product4quantity"){
         this.setState({product4quantity: event.target.value});
      }
      if (event.target.name === "product4base"){
         this.setState({product4base: event.target.value});
      }
      if (event.target.name === "product4topping"){
         this.setState({product4topping: event.target.value});
      }
      if (event.target.name === "product5quantity"){
         this.setState({product5quantity: event.target.value});
      }
      if (event.target.name === "product6topping"){
         this.setState({product6topping: event.target.value});
      }
      if (event.target.name === "product6quantity"){
         this.setState({product6quantity: event.target.value});
      }
      if (event.target.name === "product7frosting"){
         this.setState({product7frosting: event.target.value});
      }
      if (event.target.name === "product7frostingtype"){
         this.setState({product7frostingtype: event.target.value});
      }
      if (event.target.name === "product7size"){
         this.setState({product7size: event.target.value});
      }
      if (event.target.name === "product7layers"){
         this.setState({product7layers: event.target.value});
      }
      if (event.target.name === "product7addons"){
         // Use states to manipulate addons similiar to product addons
         if (event.target.value === "Colour Theme" && event.target.checked === true)
            this.setState({product7addon1: event.target.value});
         if (event.target.value === "Colour Theme" && event.target.checked === false)
            this.setState({product7addon1: false});
         if (event.target.value === "Fresh Fruit" && event.target.checked === true)
            this.setState({product7addon2: event.target.value});
         if (event.target.value === "Fresh Fruit" && event.target.checked === false)
            this.setState({product7addon2: false});
         if (event.target.value === "Cake Topper" && event.target.checked === true)
            this.setState({product7addon3: event.target.value});
         if (event.target.value === "Cake Topper" && event.target.checked === false)
            this.setState({product7addon3: false});
         if (event.target.value === "Gold Leaf" && event.target.checked === true)
            this.setState({product7addon4: event.target.value});
         if (event.target.value === "Gold Leaf" && event.target.checked === false)
            this.setState({product7addon4: false});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === true)
            this.setState({product7addon5: event.target.value});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === false)
            this.setState({product7addon5: false});
      }

      if (event.target.name === "product8addons"){
         // Use states to manipulate addons similiar to product addons
         if (event.target.value === "Colour Theme" && event.target.checked === true)
            this.setState({product8addon1: event.target.value});
         if (event.target.value === "Colour Theme" && event.target.checked === false)
            this.setState({product8addon1: false});
         if (event.target.value === "Fresh Fruit" && event.target.checked === true)
            this.setState({product8addon2: event.target.value});
         if (event.target.value === "Fresh Fruit" && event.target.checked === false)
            this.setState({product8addon2: false});
         if (event.target.value === "Cake Topper" && event.target.checked === true)
            this.setState({product8addon3: event.target.value});
         if (event.target.value === "Cake Topper" && event.target.checked === false)
            this.setState({product8addon3: false});
         if (event.target.value === "Gold Leaf" && event.target.checked === true)
            this.setState({product8addon4: event.target.value});
         if (event.target.value === "Gold Leaf" && event.target.checked === false)
            this.setState({product8addon4: false});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === true)
            this.setState({product8addon5: event.target.value});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === false)
            this.setState({product8addon5: false});
      }

      if (event.target.name === "product9addons"){
         // Use states to manipulate addons similiar to product addons
         if (event.target.value === "Colour Theme" && event.target.checked === true)
            this.setState({product9addon1: event.target.value});
         if (event.target.value === "Colour Theme" && event.target.checked === false)
            this.setState({product9addon1: false});
         if (event.target.value === "Fresh Fruit" && event.target.checked === true)
            this.setState({product9addon2: event.target.value});
         if (event.target.value === "Fresh Fruit" && event.target.checked === false)
            this.setState({product9addon2: false});
         if (event.target.value === "Cake Topper" && event.target.checked === true)
            this.setState({product9addon3: event.target.value});
         if (event.target.value === "Cake Topper" && event.target.checked === false)
            this.setState({product9addon3: false});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === true)
            this.setState({product9addon4: event.target.value});
         if (event.target.value === "Ribbon and Gift Tag" && event.target.checked === false)
            this.setState({product9addon4: false});
      }


      if (event.target.name === "product8frosting"){
         this.setState({product8frosting: event.target.value});
      }
      if (event.target.name === "product8frostingtype"){
         this.setState({product8frostingtype: event.target.value});
      }
      if (event.target.name === "product8size"){
         this.setState({product8size: event.target.value});
      }
      if (event.target.name === "product8layers"){
         this.setState({product8layers: event.target.value});
      }
      if (event.target.name === "product9quantity"){
         this.setState({product9quantity: event.target.value});
      }
      if (event.target.name === "product9quantity1"){
         this.setState({product9quantity1: event.target.value});
      }
      if (event.target.name === "product9flavour"){
         this.setState({product9flavour: event.target.value});
      }
      if (event.target.name === "product9frosting"){
         this.setState({product9frosting: event.target.value});
      }
      if (event.target.name === "product10size"){
         this.setState({product10size: event.target.value});
      }
      if (event.target.name === "product10topping"){
         this.setState({product10topping: event.target.value});
      }
      if (event.target.name === "product11quantity"){
         this.setState({product11quantity: event.target.value});
      }
      if (event.target.name === "product11drizzle"){
         this.setState({product11drizzle: event.target.value});
      }
      if (event.target.name === "product11chips"){
         this.setState({product11chips: event.target.value});
      }
      if (event.target.name === "product12quantity"){
         this.setState({product12quantity: event.target.value});
      }
      if (event.target.name === "product13quantity"){
         this.setState({product13quantity: event.target.value});
      }
      if (event.target.name === "product14quantity"){
         this.setState({product14quantity: event.target.value});
      }
      if (event.target.name === "collectiondate"){
         this.setState({collectiondate: event.target.value}); 
      }
      if (event.target.name === "boxnote"){
         this.setState({boxnote: event.target.value});
      }
      if (event.target.name === "pickuplocation"){
         this.setState({pickuplocation: event.target.value});
      }

      // Product Checkboxes
      if (event.target.name === "checkbox") {

         // Millionaire's Shortbread Bites
         if (event.target.value === "Millionaire's Shortbread Bites"){
            if (this.state.product1state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Select Quantity</Form.Label>
                     <select className="browser-default custom-select" name="product1quantity" onChange={(event) => this.handleFormChange(event)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                        <option value="110">110</option>
                        <option value="120">120</option>
                        <option value="130">130</option>
                        <option value="140">140</option>
                        <option value="150">150</option>
                     </select>
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Sea Salt (Yes/No)</Form.Label>
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product1topping"
                        value="Sea Salt - Yes"
                        id="radio"
                        label="Yes"
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product1topping"
                        value="Sea Salt - No"
                        id="radio"
                        label="No"
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                  </Form.Row>
               </Form>
               </>
               this.setState({product1state: "Millionaire's Shortbread Bites"});
               this.setState({customizemillionaires: form});
            }
            else {
               this.setState({product1state: false});
               this.setState({customizemillionaires: null});
            }
         }

         // Mini Cupcakes
         if (event.target.value === "Mini Cupcakes"){
            if(this.state.product2state === false){
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Select Box Size (Box of 20, 36 or 48)</Form.Label>
                     <select className="browser-default custom-select" name="product2quantity" onChange={(event) => this.handleFormChange(event)}>
                        <option value="Box of 20">20</option>
                        <option value="Box of 36">36</option>
                        <option value="Box of 48">48</option>
                     </select>
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Select Number of Boxes</Form.Label>
                     <select className="browser-default custom-select" name="product2quantity1" onChange={(event) => this.handleFormChange(event)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                     </select>
                  </Form.Row>

                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Flavour</Form.Label>
                     {this.state.cakeflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product2flavour"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Frosting</Form.Label>
                     {this.state.frostingflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product2frosting"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Add-Ons</Form.Label>
                  {this.state.minicupcakeaddons.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="product2addons"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
                  </Form.Row>
               </Form>
               </>
               this.setState({product2state: "Mini Cupcakes"});
               this.setState({customizeminicupcakes: form});}
            else {
               this.setState({product2state: false});
               this.setState({customizeminicupcakes: null});

            }
         }

         // Personal Cake
         if (event.target.value === "Personal Cake") {
            if(this.state.product3state === false) {   
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Flavour</Form.Label>
                     {this.state.cakeflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product3flavour"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Frosting</Form.Label>
                     {this.state.frostingflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product3frosting"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select # Of Layers</Form.Label>
                     {['2 Layers', '3 Layers'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product3layers"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
               </Form>
               </>            
               this.setState({product3state: "Personal Cake"});
               this.setState({customizepersonalcake: form});
            }

            else {
               this.setState({product3state: false});
               this.setState({customizepersonalcake: null});
            }
         }

         // Mini Cheesecakes
         if (event.target.value === "Mini Cheesecakes"){
            if(this.state.product4state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Enter Quantity (12 or More)</Form.Label>
                     <Form.Control min="12" name="product4quantity" type="number" defaultValue="12" onChange={(event) => this.handleFormChange(event)}/>
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Flavour</Form.Label>
                     {this.state.cakeflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product4flavour"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Cookie Base</Form.Label>
                     {["Oreo", "Graham Cracker", "Vanilla Teatime"].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product4base"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Topping</Form.Label>
                     {["Blueberry", "Strawberry", "Chocolate Ganache", "None"].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product4topping"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
               </Form>
               </>
               this.setState({product4state: "Mini Cheesecakes"});
               this.setState({customizeminicheesecakes: form});}
            else {
               this.setState({product4state: false});
               this.setState({customizeminicheesecakes: null});
            }
         }
         if (event.target.value === "Pineapple Upside Down Bites") {
            if (this.state.product5state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Enter Quantity (9 or More)</Form.Label>
                     <Form.Control min="9" name="product5quantity" type="number" defaultValue="9" onChange={(event) => this.handleFormChange(event)}/>
                  </Form.Row>
               </Form>
               </>
               this.setState({product5state: "Pineapple Upside Down Bites"});
               this.setState({customizepineapples: form});
            }
            else {
               this.setState({product5state: false});
               this.setState({customizepineapples: null});
            }
         }
         if (event.target.value === "Chocolate Chip Cookie Bites") {
            if (this.state.product6state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Select Quantity</Form.Label>
                     <select className="browser-default custom-select" name="product6quantity" onChange={(event) => this.handleFormChange(event)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                        <option value="110">110</option>
                        <option value="120">120</option>
                        <option value="130">130</option>
                        <option value="140">140</option>
                        <option value="150">150</option>
                     </select>
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Sea Salt (Yes/No)</Form.Label>
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product6topping"
                        value="Sea Salt - Yes"
                        id="radio"
                        label="Yes"
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product6topping"
                        value="Sea Salt - No"
                        id="radio"
                        label="No"
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                  </Form.Row>
               </Form>
               </>
               this.setState({product6state: "Chocolate Chip Cookie Bites"});
               this.setState({customizecookiebites: form});
            }
            else {
               this.setState({product6state: false});
               this.setState({customizecookiebites: null});

            }
         }
         if (event.target.value === "Chocolate Cake"){
            if(this.state.product7state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Frosting</Form.Label>
                     {this.state.frostingflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product7frosting"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Frosting Type</Form.Label>
                     {['Naked Frosting', 'Full Frosting'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product7frostingtype"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Size</Form.Label>
                     {['6 Inch', '8 Inch'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product7size"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select # Of Layers</Form.Label>
                     {['2 Layers', '3 Layers'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product7layers"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Add-Ons</Form.Label>
                  {this.state.cakeaddons.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="product7addons"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
                  </Form.Row>
               </Form>
               </>
               this.setState({product7state: "Chocolate Cake"});
               this.setState({customizechocolatecake: form});
            }
            else
            {
               this.setState({product7state: false});
               this.setState({customizechocolatecake: null});
            }
         }

         if (event.target.value === "Vanilla Cake"){
            if(this.state.product8state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>

                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Frosting</Form.Label>
                     {this.state.frostingflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product8frosting"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Frosting Type</Form.Label>
                     {['Naked Frosting', 'Full Frosting'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product8frostingtype"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Size</Form.Label>
                     {['6 Inch', '8 Inch'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product8size"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select # Of Layers</Form.Label>
                     {['2 Layers', '3 Layers'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product8layers"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Add-Ons</Form.Label>
                  {this.state.cakeaddons.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="product8addons"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
                  </Form.Row>
               </Form>
               </>
               this.setState({product8state: "Vanilla Cake"});
               this.setState({customizevanillacake: form});
            }
            else {
               this.setState({product8state: false});
               this.setState({customizevanillacake: null});
            }
         }

         if (event.target.value === "Cupcakes") {
            if (this.state.product9state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Select Box Size (Box of 9, 16 or 24)</Form.Label>
                     <select className="browser-default custom-select" name="product9quantity" onChange={(event) => this.handleFormChange(event)}>
                        <option value="9">9</option>
                        <option value="16">16</option>
                        <option value="24">24</option>
                     </select>
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Select Number of Boxes</Form.Label>
                     <select className="browser-default custom-select" name="product9quantity1" onChange={(event) => this.handleFormChange(event)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                     </select>
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Flavour</Form.Label>
                     {this.state.cakeflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product9flavour"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Frosting</Form.Label>
                     {this.state.frostingflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product9frosting"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Add-Ons</Form.Label>
                  {this.state.cupcakeaddons.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="product9addons"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
                  </Form.Row>
               </Form>
               </>
               this.setState({product9state: "Cupcakes"});
               this.setState({customizecupcakes: form});
            }
            else {
               this.setState({product9state: false});
               this.setState({customizecupcakes: null});
            }
         }

         if (event.target.value === "Pineapple Upside Down Cake") {
            if(this.state.product10state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Size</Form.Label>
                     {['6 Inch', '8 Inch', '10 Inch'].map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product10size"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Maraschino Cherries (Yes/No)</Form.Label>
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product10topping"
                        value="Maraschino Cherries (Yes)"
                        id="radio"
                        label="Yes"
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product10topping"
                        value="Maraschino Cherries (No)"
                        id="radio"
                        label="No"
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                  </Form.Row>
               </Form>
               </>
               this.setState({product10state: "Pineapple Upside Down Cake"});
               this.setState({customizeupsidedowncake: form});
            }
            else {
               this.setState({product10state: false});
               this.setState({customizeupsidedowncake: null});
            }
         }

         if (event.target.value === "Cinnamon Rolls") {
            if(this.state.product11state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Enter Quantity (6 or More)</Form.Label>
                     <Form.Control min="6" name="product11quantity" type="number" defaultValue="6" onChange={(event) => this.handleFormChange(event)}/>
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Drizzle Flavour</Form.Label>
                     {this.state.drizzleflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product11drizzle"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Select Chip Flavour</Form.Label>
                     {this.state.chipflavours.map((flavour) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="product11chips"
                        value={flavour}
                        id="radio"
                        label={flavour}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
               </Form>
               </>
               this.setState({product11state: "Cinnamon Rolls"});
               this.setState({customizecinnamonrolls: form});
            }
            else {
               this.setState({product11state: false});
               this.setState({customizecinnamonrolls: null});
            }
         }

         if (event.target.value === "Brownie Cookies"){
            if (this.state.product12state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Enter Quantity (6 or More)</Form.Label>
                     <Form.Control min="6" name="product12quantity" type="number" defaultValue="6" onChange={(event) => this.handleFormChange(event)}/>
                  </Form.Row>
               </Form>
               </>
               this.setState({product12state: "Brownie Cookies"});
               this.setState({customizebrowniecookies: form});
            }
            else {
               this.setState({product12state: false});
               this.setState({customizebrowniecookies: null});
            }
         }

         if (event.target.value === "Chocolate Chip Cookie Bars"){
            if (this.state.product13state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                     <Form.Label>Enter Quantity (12 or More)</Form.Label>
                     <Form.Control min="12" name="product13quantity" type="number" defaultValue="12" onChange={(event) => this.handleFormChange(event)}/>
                  </Form.Row>
               </Form>
               </>
               this.setState({product13state: "Chocolate Chip Cookie Bars"});
               this.setState({customizecookiebars: form});
            }
            else {
               this.setState({product13state: false});
               this.setState({customizecookiebars: null});
            }
         }

         if (event.target.value === "You Are My Heart Gift Box"){
            if (this.state.product14state === false) {
               let form = <>
               <Form id="form-style">
                  <Form.Label id="form-label">Customize {event.target.value}</Form.Label>
                  <Form.Row id="checkbox-center">
                  <Form.Label>Number of Boxes</Form.Label>
                     <select className="browser-default custom-select" name="product14quantity" onChange={(event) => this.handleFormChange(event)}>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                     </select>
                  </Form.Row>

                  {/* Collection Date */}
                  <Form.Row id="checkbox-center">
                     <Form.Label>Collection Date</Form.Label>
                        {["February 12th", "February 13th"].map((date) => (
                        <Col lg>
                        <Form.Check
                        type="radio"
                        name="collectiondate"
                        value={date}
                        id="radio"
                        label={date}
                        onChange={(event) => this.handleFormChange(event)}
                        />
                        </Col>
                     ))}
                  </Form.Row>
                  

                  {/* Box Note */}
                  <Form.Row>
                     <Form.Label>Each gift box comes with a gift tag, if you'd like us to fill out the gift tag for you, please indicate who it should be addressed to.</Form.Label>
                     <Form.Control as="textarea" name="boxnote" onChange={(event) => this.handleFormChange(event)}></Form.Control>
                  </Form.Row>
               </Form>

               </>
               this.setState({product14state: "Valentine's Day Cake Hearts"});
               this.setState({customizevalentines: form});
            }
            else {
               this.setState({product14state: false});
               this.setState({customizevalentines: null});
            }
         }
      }
   }

   handleClose = () => this.setState({modalstate: false});

   render() {
      if (this.state.redirect) {
        return <Redirect to={this.state.redirect}/>
      }
     return (
        <>
        <Row id="socialbarrow" Style="color: white; text-align: center; font-size: 0.9rem; padding: 0.7rem;">Prices are quoted in BBD. Orders should be placed 10 days in advance. A 50% deposit is required on orders of $60 and over; to be made at least 
            7 days in advance. Orders made 3 or less days in advance will incur a rush fee.</Row>
         {/*<Row id="warningbarrow" variant="warning" Style="color: white; text-align: center; font-size: 0.9rem; padding: 0.7rem;">This Enquire/Order Page is currently undergoing maintenance - Please place any orders or
         direct any queries to our Phone Number: +1 (246) 287-8427, Email Address: admin@ohsugarbb.com or our Instagram: @ohsugarbb.</Row>*/}

      <Container id="centercontainer">

         {/* Enquiry Header */}
         <h3>Have a question or would like to place an order? Fill out our Enquiry/Order form!</h3>

         {/* Form */}
         <Form>
            <div id="info">
               {/* First and Last Name */}
               <Form.Row>
                  <Col md>
                     <Form.Label id="form-label">First Name</Form.Label>
                     <Form.Control name="firstname" onChange={(event) => this.handleFormChange(event)} required />
                  </Col>
                  <Col md>
                     <Form.Label id="form-label">Last Name</Form.Label>
                     <Form.Control name="lastname" onChange={(event) => this.handleFormChange(event)} required />
                  </Col>
               </Form.Row>

               {/* Email and Phone */}
               <Form.Row>
                  <Col md>
                     <Form.Label id="form-label">Email Address</Form.Label>
                     <Form.Control name="email" onChange={(event) => this.handleFormChange(event)} required type="email"></Form.Control>
                  </Col>
                  <Col md>
                     <Form.Label id="form-label">Phone Number</Form.Label>
                     <Form.Control name="phone" onChange={(event) => this.handleFormChange(event)} required type="tel"></Form.Control>
                  </Col>
               </Form.Row>
               
               {/* Optional Fields */}
               <Form.Row className="justify-content-center topmargin">
                  <Form.Label id="thinking"><b><i>Thinking of placing an order? Tell us what you had in mind below (Optional)</i></b></Form.Label>
               </Form.Row>

               {/* Date */}
               <Form.Row>
                  <Form.Label id="form-label">Date</Form.Label>
                  <Form.Control name="date" type="date" onChange={(event) => this.handleFormChange(event)}></Form.Control>
               </Form.Row>

               {/* Products */}
               <Form.Row>
                  <Form.Label id="form-label">Product(s)</Form.Label>
               </Form.Row>
               <Form.Label id="form-label">Miniature Magic</Form.Label>
               <Form.Row id="checkbox-center">
                  {this.state.minmagicproducts.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="checkbox"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
               </Form.Row>
               <Form.Label id="form-label">Cakes & Cupcakes</Form.Label>
               <Form.Row id="checkbox-center">
                  {this.state.cakeproducts.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="checkbox"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
               </Form.Row>
               <Form.Label id="form-label">Cinnamon Rolls</Form.Label>
               <Form.Row id="checkbox-center">
                  {this.state.rollproducts.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="checkbox"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
               </Form.Row>
               <Form.Label id="form-label">Cookies</Form.Label>
               <Form.Row id="checkbox-center">
                  {this.state.cookieproducts.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="checkbox"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
               </Form.Row>
               <Form.Label id="form-label">Valentine's Day</Form.Label>
               <Form.Row id="checkbox-center">
                  {this.state.valentinesproducts.map((products) => (
                     <Col lg>
                     <Form.Check
                     type="checkbox"
                     name="checkbox"
                     value={products}
                     id="checkbox"
                     label={products}
                     onChange={(event) => this.handleFormChange(event)}
                     />
                     </Col>
                  ))}
                  
               </Form.Row>
               <hr color="white" Style="height:0.2rem;"/>
                     {this.state.customizemillionaires}
                     {this.state.customizeminicupcakes}
                     {this.state.customizepersonalcake}
                     {this.state.customizeminicheesecakes}
                     {this.state.customizepineapples}
                     {this.state.customizecookiebites}
                     {this.state.customizechocolatecake}
                     {this.state.customizevanillacake}
                     {this.state.customizecupcakes}
                     {this.state.customizeupsidedowncake}
                     {this.state.customizecinnamonrolls}
                     {this.state.customizebrowniecookies}
                     {this.state.customizecookiebars}
                     {this.state.customizevalentines}
               <hr color="white" Style="height:0.2rem;"/>
                    {/*  <button onClick={() => this.test()}>Test</button>*/}

               {/* Payment Method */}
               <Form.Row id="checkbox-center">
                  <Form.Label>Payment Method</Form.Label>
                     <select className="browser-default custom-select" name="paymentmethod" onChange={(event) => this.handleFormChange(event)}>
                        <option value="Cash">Cash</option>
                        <option value="Bank Transfer">Bank Transfer</option>
                     </select>
               </Form.Row>

               {/* Pickup or Delivery
               <Form.Row id="checkbox-center">
                  <Form.Label>Pick up or Delivery</Form.Label>
                  <select className="browser-default custom-select" name="pickuplocation" onChange={(event) => this.handleFormChange(event)}>
                     <option value="Pick up">Pick up</option>
                     <option value="Delivery">Delivery</option>
                  </select>
               </Form.Row> */}

               {/* Additional Details */}
               <Form.Row>
                  <Form.Label id="form-label">Additional Details</Form.Label>
                  <Form.Control as="textarea" placeholder="Allergies, Dietary Restrictions, Special Notes etc." name="additional" onChange={(event) => this.handleFormChange(event)}></Form.Control>
               </Form.Row>

               {/* Any Queries */}
               <Form.Row>
                  <Form.Label id="form-label">Place your queries here:</Form.Label>
                  <Form.Control name="queries" onChange={(event) => this.handleFormChange(event)} />
               </Form.Row>
            

            {/* Enquire Button */}
            <Button size="lg" variant="primary" id="submitbutton" onClick={(event) => this.handleSubmit(event)}>
               Enquire
            </Button>
            </div>
         </Form>
         {/* Modal Review for Submit Enquiry */}
         <Modal show={this.state.modalstate} onHide={this.handleClose}>
            <Modal.Header closeButton>
               <Modal.Title>Review Enquiry</Modal.Title>
               <u>Ensure that your enquiry details are correct below!</u>
            </Modal.Header>
            <Modal.Body>First Name: {this.state.firstname}</Modal.Body>
            <Modal.Body>Last Name: {this.state.lastname}</Modal.Body>
            <Modal.Body>Email Address: {this.state.email}</Modal.Body>
            <Modal.Body>Phone Number: {this.state.phone}</Modal.Body>
            <Modal.Body>Date (YYYY-MM-DD): {this.state.date}</Modal.Body>
            <Modal.Body>
               Product(s): 
               <p>{this.state.product1state === false ? "" : `${this.state.product1state} (Quantity: ${this.state.product1quantity}, ${this.state.product1topping})`}</p>
               <p>{this.state.product2state === false ? "" : `${this.state.product2state} (Box Size: ${this.state.product2quantity}, Number of Boxes: ${this.state.product2quantity1}, Flavour: ${this.state.product2flavour}, Frosting: ${this.state.product2frosting}, Add-Ons: ${this.state.product2addon1 === false ? "" : this.state.product2addon1}, ${this.state.product2addon2 === false ? "" : this.state.product2addon2})`}</p>
               <p>{this.state.product3state === false ? "" : `${this.state.product3state} (Flavour: ${this.state.product3flavour}, Frosting: ${this.state.product3frosting}), Layers: ${this.state.product3layers})`}</p>
               <p>{this.state.product4state === false ? "" : `${this.state.product4state} (Quantity: ${this.state.product4quantity}, Flavour: ${this.state.product4flavour}, Cookie Base: ${this.state.product4base}, Topping: ${this.state.product4topping})`}</p>
               <p>{this.state.product5state === false ? "" : `${this.state.product5state} (Quantity: ${this.state.product5quantity})`}</p>
               <p>{this.state.product6state === false ? "" : `${this.state.product6state} (Quantity: ${this.state.product6quantity}, ${this.state.product6topping})`}</p>
               <p>{this.state.product7state === false ? "" : `${this.state.product7state} (Frosting: ${this.state.product7frosting}, Frosting Type: ${this.state.product7frostingtype}, Size: ${this.state.product7size}, Layers: ${this.state.product7layers}, Add-Ons: ${this.state.product7addon1 === false ? "" : this.state.product7addon1}, ${this.state.product7addon2 === false ? "" : this.state.product7addon2}, ${this.state.product7addon3 === false ? "" : this.state.product7addon3}, ${this.state.product7addon4 === false ? "" : this.state.product7addon4}, ${this.state.product7addon5 === false ? "" : this.state.product7addon5})`}</p>
               <p>{this.state.product8state === false ? "" : `${this.state.product8state} (Frosting: ${this.state.product8frosting}, Frosting Type: ${this.state.product8frostingtype}, Size: ${this.state.product8size}, Layers: ${this.state.product8layers}, Add-Ons: ${this.state.product8addon1 === false ? "" : this.state.product8addon1}, ${this.state.product8addon2 === false ? "" : this.state.product8addon2}, ${this.state.product8addon3 === false ? "" : this.state.product8addon3}, ${this.state.product8addon4 === false ? "" : this.state.product8addon4}, ${this.state.product8addon5 === false ? "" : this.state.product8addon5})`}</p>
               <p>{this.state.product9state === false ? "" : `${this.state.product9state} (Box Size: ${this.state.product9quantity}, Number of Boxes: ${this.state.product9quantity1}, Flavour: ${this.state.product9flavour}, Frosting: ${this.state.product9frosting}, Add-Ons: ${this.state.product9addon1 === false ? "" : this.state.product9addon1}, ${this.state.product9addon2 === false ? "" : this.state.product9addon2}, ${this.state.product9addon3 === false ? "" : this.state.product9addon3}, ${this.state.product9addon4 === false ? "" : this.state.product9addon4})`}</p>
               <p>{this.state.product10state === false ? "" : `${this.state.product10state} (Size: ${this.state.product10size}, Topping: ${this.state.product10topping})`}</p>
               <p>{this.state.product11state === false ? "" : `${this.state.product11state} (Quantity: ${this.state.product11quantity}, Drizzle Flavour: ${this.state.product11drizzle}, Chip Flavour: ${this.state.product11chips})`}</p>
               <p>{this.state.product12state === false ? "" : `${this.state.product12state} (Quantity: ${this.state.product12quantity})`}</p>
               <p>{this.state.product13state === false ? "" : `${this.state.product13state} (Quantity: ${this.state.product13quantity})`}</p>
               <p>{this.state.product14state === false ? "" : `${this.state.product14state} (Number of Boxes: ${this.state.product14quantity}, Collection Date: ${this.state.collectiondate}, Pickup Location: ${this.state.pickuplocation}, Gift Tag: ${this.state.boxnote})`}</p>
            </Modal.Body>
            <Modal.Body>Payment Method: {this.state.paymentmethod}</Modal.Body>
            <Modal.Body>Additional Details: {this.state.additional}</Modal.Body>
            <Modal.Body>Queries: {this.state.queries}</Modal.Body>
            <Modal.Footer className="justify-content-center">
               <Button variant="success" onClick={() => this.confirmSubmit()}>
                  Submit Enquiry
               </Button>
               <Button variant="danger" onClick={this.handleClose}>
                  Re-Enter Enquiry
               </Button>
            </Modal.Footer>
            </Modal>
      </Container>
      </>
     );
   }
}
export default Enquiry;