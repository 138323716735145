import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import pineapple from './media/pineapple.jpg';
import chocchipbites from './media/chocchipbites.jpg';
import personalcake from './media/personalcake.jpg';
import cupcakes from './media/cupcakes.jpg';
import millionaires from './media/millionaires.jpg';
import minicheesecake from './media/minicheesecake.jpg';


class Minmagicshop extends React.Component{
    // Constructor
    constructor(props){
        super(props);

        // Set default states and values
        this.state = {
            minicupcakes: '', minicupcakesstate: false, minicupcakesbutton: 'Show Details',
            minimillionaires: '', minimillionairesstate: false, minimillionairesbutton: 'Show Details',
            minichocchip: '', minichocchipstate: false, minichocchipbutton: 'Show Details',
            minidoughnuts: '', minidoughnutsstate: false, minidoughnutsbutton: 'Show Details',
            personalcake: '', personalcakestate: false, personalcakebutton: 'Show Details',
            samplerbox: '', samplerboxstate: false, samplerboxbutton: 'Show Details'};
    }

    // Function for Product Details Click
    toggleHideShow(item){
        if (item === "minicupcakes")
        {
            if (this.state.minicupcakesstate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> Bite-size mini cupcakes, cute enough for any occassion
                    </div>
                    <hr></hr>
                    <div><b>Quantities:</b> Minimum order of 20</div>
                    <hr></hr>
                    <div><b>Price: </b>$1.50 - $2 Each</div>
                    <hr></hr>
                    <div><b>Cake Flavours: </b>vanilla, chocolate, mint, coconut, oreo<br/><i>*Other flavours
                            available upon request</i></div>
                    <hr/>
                    <div><b>Frosting Flavours:</b><br/><b>Buttercream: </b>chocolate, vanilla, mint, coconut, caramel,
                    marshmallow<br/><b>Ganache: </b>semi-sweet/milk chocolate</div>
                </div>
                this.setState({minicupcakes: details});
                this.setState({minicupcakesstate: true});
                this.setState({minicupcakesbutton: 'Hide Details'});
            }
    
            if(this.state.minicupcakesstate === true){
                this.setState({minicupcakes: ''});
                this.setState({minicupcakesstate: false});
                this.setState({minicupcakesbutton: 'Show Details'});
            }
        }

        if (item === "minimillionaires")
        {
            if (this.state.minimillionairesstate === false){
                let details = 
                <div class="topmargin1">
                    <div><b>Description:</b> Layers of buttery shortbread, homemade caramel & semi-sweet chocolate
                    </div>
                    <hr></hr>
                    <div><b>Quantities: </b>Minimum order of 10</div>
                    <hr></hr>
                    <div><b>Prices: </b>$2.00 Each</div>
                    <hr></hr>
                    <div><b>Add-ons: </b>Sea Salt</div>
                </div>
                this.setState({minimillionaires: details});
                this.setState({minimillionairesstate: true});
                this.setState({minimillionairesbutton: 'Hide Details'});
            }
    
            if(this.state.minimillionairesstate === true){
                this.setState({minimillionaires: ''});
                this.setState({minimillionairesstate: false});
                this.setState({minimillionairesbutton: 'Show Details'});
            }
        }

        if (item === "minichocchip")
        {
            if (this.state.minichocchipstate === false){
                let details = 
                <div className="topmargin1">
                    <div><b>Description:</b> A bite-size semi sweet chocolate chip brown butter cookie with a
                    dulce de leche surprise center
                    </div>
                    <hr></hr>
                    <div><b>Quantities:</b> Minimum order of 10</div>
                    <hr></hr>
                    <div><b>Price: </b>$2.00 Each</div>
                    <hr></hr>
                    <div><b>Add-ons: </b>Sea Salt</div>
                </div>
                this.setState({minichocchip: details});
                this.setState({minichocchipstate: true});
                this.setState({minichocchipbutton: 'Hide Details'});
            }
    
            if(this.state.minichocchipstate === true){
                this.setState({minichocchip: ''});
                this.setState({minichocchipstate: false});
                this.setState({minichocchipbutton: 'Show Details'});
            }
        }

        if (item === "minidoughnuts")
        {
            if (this.state.minidoughnutsstate === false){
                let details = 
                <div className="topmargin1">
                    <div><b>Description:</b> Cute personal sized pineapple flavoured cakes topped with
                    carmelized pineapple & a maraschino cherry
                    </div>
                    <hr></hr>
                    <div><b>Quantities:</b> Minimum order 9</div>
                    <hr></hr>
                    <div><b>Price: </b>$4 Each</div>
                </div>
                this.setState({minidoughnuts: details});
                this.setState({minidoughnutsstate: true});
                this.setState({minidoughnutsbutton: 'Hide Details'});
            }
    
            if(this.state.minidoughnutsstate === true){
                this.setState({minidoughnuts: ''});
                this.setState({minidoughnutsstate: false});
                this.setState({minidoughnutsbutton: 'Show Details'});

            }
        }

        if (item === "personalcake")
        {
            if (this.state.personalcakestate === false){
                let details = 
                <div className="topmargin1">
                    <div><b>Description:</b> 3 inch cake suitable for 1-2 persons<br/><br/>
                    Available in either 2 layers or 3 layers
                    </div>
                    <hr></hr>
                    <div><b>Price: </b>2 layers - $20, 3 layers - $25</div>
                    <hr></hr>
                    <div><b>Cake Flavours: </b>vanilla, chocolate, mint, coconut<br/><i>*Other flavours
                            available upon request</i></div>
                    <hr/>
                    <div><b>Frosting Flavours:</b><br/><b>Buttercream: </b>chocolate, vanilla, mint, coconut, caramel,
                    marshmallow<br/><b>Ganache: </b>semi-sweet/milk chocolate</div>
                </div>
                this.setState({personalcake: details});
                this.setState({personalcakestate: true});
                this.setState({personalcakebutton: 'Hide Details'});
            }
    
            if(this.state.personalcakestate === true){
                this.setState({personalcake: ''});
                this.setState({personalcakestate: false});
                this.setState({personalcakebutton: 'Show Details'});

            }
        }

        if (item === "minicheesecakes")
        {
            if (this.state.samplerboxstate === false){
                let details = 
                <div className="topmargin1">
                    <div><b>Description: </b>Bite-size mini cream cheese delights with a crunchy cookie base and topping of your choice<br/>
                    </div>
                    <hr></hr>
                    <div><b>Quantities:</b> Minimum order 12</div>
                    <hr></hr>
                    <div><b>Price: </b>$3.50 Each</div>
                    <hr></hr>
                    <div><b>Cake Flavours: </b>vanilla, chocolate, mint, coconut, pineapple<br/><i>*Other flavours
                            available upon request</i></div>
                    <hr/>
                    <div><b>Cookie Base Options: </b>oreo, graham cracker, vanilla teatime</div>
                    <hr/>
                    <div><b>Toppings: </b>blueberry, strawberry, chocolate ganache, none</div>
                    
                </div>
                this.setState({samplerbox: details});
                this.setState({samplerboxstate: true});
                this.setState({samplerboxbutton: 'Hide Details'});
            }
    
            if(this.state.samplerboxstate === true){
                this.setState({samplerbox: ''});
                this.setState({samplerboxstate: false});
                this.setState({samplerboxbutton: 'Show Details'});
            }
        }
        
    }

    render(){
        return (
            // Main Container
            <>
            <Row id="socialbarrow" Style="color: white; text-align: center; font-size: 0.9rem; padding: 0.7rem;">Prices are quoted in BBD. Orders should be placed 10 days in advance. A 50% deposit is required on orders of $60 and over; to be made at least 
            7 days in advance. Orders made 3 or less days in advance will incur a rush fee.</Row>
            <Container id="centercontainer">

                {/* Cursive Text Miniature Magic Header */}
                <div className="minmagicheader">
                    <span id="cursivetext1">
                        Welcome to our<br/>
                        <span id="standouttext"> Miniature Magic </span> line -
                    </span><br/>
                    <span id="normalfont">a line of magical mini delights designed
                    to tantalize your taste buds in tiny bites.
                    Created by us, just for you -</span>
                    <Row><Col></Col><Col id="signature">Akilah & Saam <svg id="heart" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17.516 3c2.382 0 4.487 1.564 4.487 4.712 0 4.963-6.528 8.297-10.003 11.935-3.475-3.638-10.002-6.971-10.002-11.934 0-3.055 2.008-4.713 4.487-4.713 3.18 0 4.846 3.644 5.515 5.312.667-1.666 2.333-5.312 5.516-5.312zm0-2c-2.174 0-4.346 1.062-5.516 3.419-1.17-2.357-3.342-3.419-5.515-3.419-3.403 0-6.484 2.39-6.484 6.689 0 7.27 9.903 10.938 11.999 15.311 2.096-4.373 12-8.041 12-15.311 0-4.586-3.414-6.689-6.484-6.689z"/></svg></Col></Row>
                </div>
                <hr></hr>

                {/* Miniature Magic Products */}
                <div className="shopdropdown bottommargin">
                <Row>
                        {/* Miniature Millionaires */}
                        <Col className="bottommargin" xl>
                            <Image fluid src={millionaires} rounded alt="minimillionairesimage"/>
                            <div className="cursivetext minmagiccaptions">Millionaire's Shortbread Bites</div>
                            <Button className="minmagicbutton" onClick={() => this.toggleHideShow('minimillionaires')}>{this.state.minimillionairesbutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.minimillionaires}
                            </Row>
                        </Col> 
                        
                        {/* Miniature Choc Chip Bars*/}
                        <Col className="bottommargin" xl>
                            <Image fluid src={chocchipbites} rounded alt="minichocchipimage"/>
                            <div className="cursivetext minmagiccaptions">Chocolate Chip Cookie Bites</div>
                            <Button className="minmagicbutton" onClick={() => this.toggleHideShow('minichocchip')}>{this.state.minichocchipbutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.minichocchip}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* Personal Cake */}
                        <Col className="bottommargin" xl>
                            <Image fluid src={personalcake} rounded alt="personalcakeimage"/>
                            <div className="cursivetext minmagiccaptions">Personal Cake</div>
                            <Button className="minmagicbutton" onClick={() => this.toggleHideShow('personalcake')}>{this.state.personalcakebutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.personalcake}
                            </Row>
                        </Col>
                         {/* Miniature Pineapple Upside Down Cake */}
                         <Col className="bottommargin" xl>
                            <Image fluid src={pineapple} rounded alt="minidoughnutsimage"/>
                            <div className="cursivetext minmagiccaptions">Pineapple Upside Down Bites</div>
                            <Button className="minmagicbutton" onClick={() => this.toggleHideShow('minidoughnuts')}>{this.state.minidoughnutsbutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.minidoughnuts}
                            </Row>
                        </Col>
                        
                    </Row>
                    <Row>
                        {/* Miniature Cupcakes */}
                        <Col className="bottommargin" xl>
                            <Image fluid src={cupcakes} rounded alt="minicupcakesimage"/>
                            <div className="cursivetext minmagiccaptions">Mini Cupcakes</div>
                            <Button className="minmagicbutton" onClick={() => this.toggleHideShow('minicupcakes')}>{this.state.minicupcakesbutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.minicupcakes}
                            </Row>
                        </Col>
                        
                        <Col className="bottommargin" xl>
                            <Image fluid src={minicheesecake} rounded alt="minicupcakesimage"/>
                            <div className="cursivetext minmagiccaptions">Mini Cheesecakes</div>
                            <Button className="minmagicbutton" onClick={() => this.toggleHideShow('minicheesecakes')}>{this.state.samplerboxbutton}</Button>
                            <Row className="justify-content-center">
                                {this.state.samplerbox}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
            </>
        );
    }
}
export default Minmagicshop;