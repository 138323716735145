import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import img from './media/logo.jpeg';

const Charity = () => {
    return(
        // Main Container
        <Container fluid>
            {/* About Charity */}
            <Row id="aboutcharity">
                {/* Charity Title */}
                <h1 id="aboutsubtitle">Charity</h1>
            </Row>

            {/* Charity Text */}
            <Row id="abouttext">
                The Featured Charity for the month of April is:
            </Row>
            <Row id="abouttext">
                The Rotaract Club of Barbados
            </Row>

            <Row id="abouttext">
                <Image fluid src={img} rounded alt="rotaract logo"/>
            </Row>
            
            <Row id="abouttext">
                Rotaract is the Rotary International’s service club program for young adults ages 18-30.
                Rotaractors repeatedly show that they are prepared for <i>Fellowship Through Service.</i> The
                program was adopted as a means of channelling the energies of young adults into positive
                activities that could benefit their communities.
            </Row>

            <Row id="abouttext">
                Today, there are currently more than 7,500 Rotaract clubs in more than 155 countries, with an
                estimated membership of more than 173,000 Rotaractors. Rotaract International continues to
                experience phenomenal growth.            
            </Row>
            
            <Row id="abouttext">
                The Rotaract Club of Barbados of was founded in 2016 with the aim of <i>Community Service
                and Professional Development.</i> Members do not only give back to the community through
                service projects and fundraisers such as Food Drives, Blood Donation Drives, Tree Planting
                and Treasure Hunts, but also learn about Leadership, Money Management, Personal Branding
                and Career Development whilst doing so.
            </Row>

            <Row id="abouttext">
                This month, Oh Sugar! will be making a donation to one of the club’s many projects. Be sure
                to look out for that later this month over on our Instagram page.
            </Row>

            <Row id="abouttext">
                To find out more about the Rotaract Club of Barbados and about their upcoming projects visit
                their website: <a href="https://rotaractbarbados.org" rel="noopener noreferrer" target="_blank">rotaractbarbados.org </a>
                or Instagram page: <a href="https://instagram.com/rotaractbarbados" rel="noopener noreferrer" target="_blank"> instagram.com/rotaractbarbados</a>
            </Row>
            {/* Charity Images */}

            {/* Close Statement */}
            <Row id="aboutsubtitle" className="justify-content-center bottommargin">
                <h2 id="homecontainer">Love, Akilah & Saam <span role="img" aria-label="heart">❤️</span></h2>     
            </Row>
        
        </Container>
    )
}
export default Charity;